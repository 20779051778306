

const subHeader = [
  {
    href: '/home',
    title: 'Home'
  }
];


export { subHeader };
